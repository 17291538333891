// 業種・地域企業検索の結果数表示
// 連続クリック防止用にチェックボックス押下後1秒はajaxのリクエストは行わない
$(function () {

    // チェックボックスクリック後、1秒間はajaxリクエストを行わない
    const interval = 1000;

    const $modal = $('#searchCompanyModal');
    const $searchCount = $modal.find('.c-modal-search-num__num');
    const action = $modal.data('action');

    const $loading = $('<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>');

    let jqxhr;
    const showLoading = () => {
        if (jqxhr) {
            jqxhr.abort();
        }
        $searchCount.html($loading);
    };
    const showResult = (total) => {
        $searchCount.html(`${total}<small>件</small>`);
    };
    const hideLoading = () => {
        $loading.remove();
    };

    // 連続クリック防止用タイマー
    let timer;

    // チェックボックス押下時に検索結果数の取得を行う
    $modal.find('input[type="checkbox"]').on('click', (e) => {
        if (timer) {
            clearTimeout(timer);
        }
        showLoading();

        timer = setTimeout(function() {
            const params = {
                'industry_ids': [],
                'prefecture_ids': [],
            };
            $modal.find('input[name="industry_ids[]"]:checked').each(function(index, element) {
                params['industry_ids'].push(Number(element.value));
            });
            $modal.find('input[name="prefecture_ids[]"]:checked').each(function(index, element) {
                params['prefecture_ids'].push(Number(element.value));
            });

            jqxhr = $.getJSON(action, params)
                .done(function(data,textStatus,jqXHR) {
                    showResult(data.response.total);
                })
                .fail(function(jqXHR, textStatus, errorThrown ) {
                })
                .always(function() {
                    hideLoading();
                });
        }, interval);
    })
})
